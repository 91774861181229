.footer{
    width: 100%;
    overflow: hidden;
}

.footer-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

@media screen and (min-width: 767px){
    #footer{
        margin-left: 120px;
    }
}


@media screen and (max-width: 700px){
    .footer-content{
        margin: 0;
    }
}

.facebook-icon {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#foot1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

#foot2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    left: 0;
}

#foot3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#foot3 .button{
    border: none;
    background: linear-gradient(to right, #6CD4F7, #6A7EFB, #B570FF, #1C17D6);
    padding: 1rem 2rem;
    color: white;
    font-size: 20px;
    font-weight: 700;
    display: flex;
}

.arrow-circle {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6A7EFB;
    font-size: 24px; /* Adjust the font size as needed */
}

.hor-line{
    box-sizing: border-box;
    border-bottom: 3px solid #3E58A0;
    width: 60px;
}