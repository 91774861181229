#navbar{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 0 .3rem;
    box-shadow: 5px 5px 20px rgba(0,0,0,.5);
    background: #fff;
    max-width: 100vw;
    overflow: hidden;
}

.navbar-heading{
    font-family: "Roboto", sans-serif;
}

.navbar-heading h1{
    font-size: 30px;
}

.header-box{
    background: linear-gradient(90deg, #3C58A1 1.67%, #D94539 100%);
    padding: 5px;
}

.container-fluid .navbar-light{
    margin-top: -10px;
    margin-bottom: -10px;
}

.container-fluid{
    display: flex;
    flex-direction: column;
}


.small-input input{
    height: 50px;
}

.navbar .navbar-brand{
    width: 100px;
    height: 100px;
}


#navbarSupportedContent{
    background: #3E58A0;
    min-width: 101vw;
    margin-left: -22px;
}

#navbarSupportedContent ul{
   width: 100vw;
   display: flex;
   justify-content: right;
   padding: 0 2rem;
}

#navbarSupportedContent a{
    color: #fff;
    padding: .5rem 1.5rem;
}


.new-button{
    height: 50px;
    display: flex;
    align-items: center;
}


.navbar-toggler{
    background: #D94539 !important;
}

@media screen and (max-width: 767px){
    .navbar-toggler{
        margin-top: -200px;
    }
    .input1{
        margin-right: -65px;
    }

    .navbar-heading h1{
        font-size: 15px;
        margin-left: -20px;
    }
    
}

@media screen and (max-width: 540px){
    .navbar-heading h1{
       display: none;
    }
}

