/* .container{
    min-height: 100vh;
} */

.About{
    text-align: left;
}

.About h1{
    color: #3E58A0;
}

.About span{
    color: #B22828;
}

