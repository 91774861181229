.programmes{
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
}

.program-bg {
    position: relative;
    background: linear-gradient(120.64deg, rgba(71, 122, 235, 0.8) 15.77%, rgba(11, 40, 104, 0.8) 44.85%, rgba(138, 53, 197, 0.8) 96.03%), url(../assets/program.png) no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.program-area{
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 991px){
    .custom-gallery #event-img{
        height: 300px;
    }
}

@media screen and (max-width: 767px){
    .program-first-comp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        margin-left: -40px;
    }
    .program-bg h1{
        font-size: 40px !important;
    }

    .program-area #why-container{
        margin-top: -10px !important;
    }

    .program-area #ties{
        margin-top: -10px !important;
    }

    .program-area #why{
        font-size: 30px !important;
    }

    .program-area #partn{
        font-size: 30px !important;
    }

    .program-area #blue-img{
        display: none;
    }

    #asso{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-bottom: 10px !important;
    }

    #asso-sub{
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
    }

    #box-height{
        width: 50%;
        height: 15vh !important;
        margin-left: -15px;
    }

    #box-height h4{
        font-size: 10px;
        margin-top: -10px;
    }

    #img-height{
        width: 50%;
        height: 15vh;
    }

    #img-height img{
        width: 100%;
        height: 100%;
        margin-top: -11px;
        margin-right: -15px;
    }

    #follow-comp{
        margin-bottom: 20px;
    }

    #highlight-comp{
        margin-bottom: 20px !important;
        height: fit-content !important;
    }

    #upcoming-comp{
        margin-left: 8px !important;
    }

    #upcom-img{
        display: none;
    }

    #president{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    #cyber{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}
